.menu-bt{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1px solid #DFE3E8;
    justify-content: space-around;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0 1px 4px;
}
.menu-bt-disabled{
    background-color: rgba(204, 204, 204, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1px solid #e0dada;
    justify-content: space-around;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0 1px 4px;
}

.menu-bt-text{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: #637381;
    font-weight: 600;
    font-size: small;
}
.menu-bt-item{
    background: #cccccc;
    border-radius: 0.5rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
.menu-bt-item-disabled{
    background-color: rgba(204, 204, 204, 0.2);
    border-radius: 0.5rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: not-allowed;
}

.menu-bt-item:hover{
    background: #F6BB60;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.menu-bt-item:not(:hover){
    background: #F4F6F8;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.menu-bt-item img:hover{
    transform: scale(1.2);
    transition-duration: 0.50s;
    cursor: pointer;
}
.menu-bt-item img:not(:hover){
    transform: scale(1.0);
    transition-duration: 0.50s;
    cursor: pointer;
}


.menu-bt-item svg{
    width: 94%;
    height: 94%;
    padding: 1rem;
    color: #454F5B;
}
.menu-bt-item svg:hover{
    transform: scale(1.5);
    transition-duration: 0.50s;
    cursor: pointer;
    color: rgba(99, 115, 129, 0.8);
}
.menu-bt-item svg:not(:hover){
    transform: scale(1.0);
    transition-duration: 0.50s;
    cursor: pointer;
}
