.cardTitleContainer{
    margin: 0;
    height: 2rem;
    text-align: center;
    padding-top: 0.5rem;
}

.cardContainer{
    background-color: #ffffff;
    padding: 0.3rem;
    min-height: 6rem;

}

.cardTitleContainer.alta{
    background: rgb(254,175,175);
    background: linear-gradient(215deg, rgba(254,175,175,0.6054796918767507) 0%, rgba(239,239,255,1) 18%, rgba(237,253,255,1) 100%);
}
.cardTitleContainer.baixa{
    background: rgb(182,254,175);
    background: linear-gradient(215deg, rgba(182,254,175,0.6054796918767507) 0%, rgba(239,239,255,1) 18%, rgba(237,253,255,1) 100%);
}
.cardTitleContainer.media{
    background: rgb(254,245,175);
    background: linear-gradient(215deg, rgba(254,245,175,0.6054796918767507) 0%, rgba(239,239,255,1) 18%, rgba(237,253,255,1) 100%);
}
.late{
    color: #FF6969;
}
.no-late{
    color: #2f2d2d;
}
.actionDays{
    font-size: 0.8rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    width: 50%;
}
.titleCard {
    font-size: 0.8rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}
.subTitleCard {
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    text-align: center;
}
.caixas{
    text-transform: uppercase;
    font-weight: 800;
    color: rgba(11, 19, 49, 0.62);
    font-size: 0.8rem;
}

.nome{
    text-transform: uppercase;
    font-weight: 800;
    color: #0b1331;
}
