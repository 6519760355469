.rbc-agenda-table tr {
  color: #000 !important;
  background-color: transparent !important;
}

@media print {
  * {
    overflow: visible !important;
  }

  .naoImprime,
  .tooltip-inner,
  #tooltip,
  .sidebar,
  .navbar,
  .agendaHeader .col-md-3,
  .agendaHeader .col-md-5,
  .agendaHeader .col-md-4,
  .agendaHeader button {
    display: none !important;
    visibility: hidden;
    opacity: 0;
  }

  .main-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    display: block;
  }

  .main-panel > .content {
    padding: 0;
    display: block;
  }

  .agendaHeader,
  .agendaHeader .col-md-4 {
    width: 100% !important;
    max-width: 100% !important;
    text-align: center !important;
    display: block !important;
  }

  .row {
    display: block;
    position: relative !important;
  }

  .rbc-agenda-view {
    display: block;
    position: relative !important;
  }

  .rbc-calendar {
    display: block;
    position: relative !important;
  }

  .diaAgenda {
    display: block !important;
    position: relative !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
    -webkit-region-break-after: always !important;
    -webkit-region-break-inside: avoid !important;
  }
}

textarea.form-control {
  line-height: 17px !important;
}

textarea.form-control.descricaoEvento {
  height: 160px !important;
  max-height: 160px !important;
  min-height: 80px !important;
}

.eventoMes {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 10px;
  font-weight: 800;
}

.rbc-today {
  background: #d8eaff;
}

.rbc-calendar {
  background: #fff;
  border-radius: 5px;
}

.inputBusca {
  width: CALC(100% - 45px) !important;
}

.btnBusca {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.diaAgendaTitle {
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: bold;
}

.rbc-month-row {
  min-height: 115px;
}

fieldset {
  border-radius: 8px;
  padding: 8px;
  border: solid 1px rgb(99, 115, 129);
}

fieldset legend {
  transform: scale(0.75);
}
@media screen and (min-width: 601px) {
  .toolbarBtn {
    font-size: 12px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .toolbarBtn {
    font-size: 2.5vw;
  }
}
