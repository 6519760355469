.boardKanban {
    display : flex;
    flex-direction : row;
    overflow-y : hidden;
    overflow-x : auto;
    padding : 0rem;
}
.boardKanban::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    background-clip: padding-box;
    border: 6px solid transparent;
}
.boardKanban::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.40);
    background-clip: padding-box;
    border-radius: 20px;
    border: 4px solid transparent;
}
