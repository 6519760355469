.bg-login{
    background-image: url("/public/static/card/login-blue.svg") !important;
    background-repeat: no-repeat;
    background-size: auto;
    height: 100%;
}

.css-1fpolj1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background: linear-gradient(#DFE3E8, #637381);
}
.add-button {
    position: absolute;
    top: 1px;
    left: 1px;
}
