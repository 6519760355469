.paper-card-default {
    background-color: rgba(160, 186, 241, 0.2);
    padding : 0.2rem;
    margin : 0.0rem;
    min-width: 20rem;
    height: 80vh;
}
.paper-card {
    background-color: #eaeaea;
    padding : 0.1rem;
    margin : 0.1rem;
    min-width: 20rem;
    height: 80vh;
}
.paper-card-content::-webkit-scrollbar,
.paper-card-content::-webkit-scrollbar-thumb {
    width: 26px;

    border-radius: 13px;
    background-clip: padding-box;
    border: 9px solid transparent;
}
.paper-card-content::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.40);
}

.paper-card-content {
    overflow: auto !important;
    height: 80%;
}

.paper-card-title{
    padding: 0.2rem;
    width: 100%;
}

.paper-card-title-label{
    font-size: 0.9rem;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.btn-cor {
    border: 1px solid rgb(216, 216, 216) !important;
    height: 4rem;
    width: 4rem;
    margin: 0.2rem;
}

.btn-cor:hover {
    border: 0.4rem solid rgba(20, 39, 114, 0.68) !important;
}
.panel-colors{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}