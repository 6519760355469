
.itemCard {
    padding : 0.0rem;
    margin : 0.5rem;
    min-height: 10rem;
    min-width: 10rem;
}

.days{
    background-color: #FF6969;
}
.on-time{
    background-color: #A8DF8E;
}
.alta{
    background: rgb(254,175,175);
    background: linear-gradient(31deg, rgba(254,175,175,0.6054796918767507) 0%, rgba(250,250,255,1) 18%, rgba(248,254,255,1) 100%);
}
.baixa{
    background: rgb(182,254,175);
    background: linear-gradient(31deg, rgba(182,254,175,0.6054796918767507) 0%, rgba(250,250,255,1) 18%, rgba(248,254,255,1) 100%);
}
.media{
    background: rgb(248,254,175);
    background: linear-gradient(31deg, rgba(248,254,175,0.6054796918767507) 0%, rgba(250,250,255,1) 18%, rgba(248,254,255,1) 100%);
}